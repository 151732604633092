import React, { useState } from "react";
import "./App.css";
import { Routes, Route, useLocation, HashRouter, BrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import Navbar from "./components/Navbar/Navbar";
import HomePage from "./pages/HomePage";
import Footer from './components/footer/footer'
import QuickbooksHosting from "./pages/QuickbooksHosting";
import QuickboksPremierHosting from "./pages/QuickbooksPremierHosting ";
import QuickBooksPro from "./pages/QuickbooksPro";
import QuickBooksEnterprise from "./pages/QuickbooksEnterprise";
import QuickBooksPOS from "./pages/QuickbooksPOS";
import QuickBooksAddOns from "./pages/QuickbooksAddOns";
// import BuyQuickBooks from "./pages/BuyQuickbooks";
import SageHosting from "./pages/SageHosting";
import Sage50Hosting from "./pages/Sage50Hosting";
import Sage100Hosting from "./pages/Sage100Hosting";
import Sage300Hosting from "./pages/Sage300Hosting";
import Sage500Hosting from "./pages/Sage500Hosting";
import TaxHosting from "./pages/TaxHosting";
import DrakeTaxSoftware from "./pages/DrakeTaxSoftware";
import UltraTaxCS from "./pages/UltraTaxCS";
import LacerteHosting from "./pages/LacerteHosting";
import ProSeriesHosting from "./pages/ProSeriesTaxHosting";
import TaxWiseHosting from "./pages/TaxWiseHosting";
import SmallBusiness from "./pages/SmallBusiness";
import LawFirm from "./pages/LawFirm";
import HostedVirtualDesktop from "./pages/HostedVirtualDesktop";
import ManagedITServices from "./pages/ManagedITServices";
import ContactUs from "./pages/Contactus";
import Price from "./pages/Price";
import FreeTrial from "./pages/FreeTrial";
import AboutUs from "./pages/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ThankYouPage from "./pages/ThankYou";
import QuickBooksCanada from "./pages/QuickbooksCanada";
import QuickBooksAccountant from "./pages/QuickbooksAccountant";
function App(props) {

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };


  return (
    <BrowserRouter>
      <Navbar />
      <ScrollToTop />
      <Routes >
        <Route path="/reload" component={null} key="reload" />
        <Route path="/" element={<HomePage />} />
        <Route path="/quickbooks-hosting" element={<QuickbooksHosting />} />
        <Route path="/quickbooks-premier-hosting" element={<QuickboksPremierHosting />} />
        <Route path="/quickbooks-enterprise-hosting" element={<QuickBooksEnterprise />} />
        <Route path="/quickbooks-pro-hosting" element={<QuickBooksPro />} />
        <Route path="/quickbooks-pos-hosting" element={<QuickBooksPOS />} />
        <Route path="/quickbooks-add-ons-hosting" element={<QuickBooksAddOns />} />
        <Route path="/sage-hosting" element={<SageHosting />} />
        <Route path="/sage-50-hosting" element={<Sage50Hosting />} />
        <Route path="/sage-100-hosting" element={<Sage100Hosting />} />
        <Route path="/sage-100-erp-hosting" element={<Sage100Hosting />} />
        <Route path="/sage-300-hosting" element={<Sage300Hosting />} />
        <Route path="/sage-300-erp-hosting" element={<Sage300Hosting />} />
        <Route path="/sage-500-hosting" element={<Sage500Hosting />} />
        <Route path="/sage-500-erp-hosting" element={<Sage500Hosting />} />
        <Route path="/tax-hosting" element={<TaxHosting />} />
        <Route path="/tax-software-hosting" element={<TaxHosting />} />
        <Route path="/tax-drake-hosting" element={<DrakeTaxSoftware />} />
        <Route path="/drake-tax-software-hosting" element={<DrakeTaxSoftware />} />
        <Route path="/ultra-tax-cs" element={<UltraTaxCS />} />
        <Route path="/ultra-tax-cs-hosting" element={<UltraTaxCS />} />

        <Route path="/lacerte-hosting" element={<LacerteHosting />} />
        <Route path="/pro-series-hosting" element={<ProSeriesHosting />} />
        <Route path="/pro-series-tax-software-hosting" element={<ProSeriesHosting />} />

        <Route path="/tax-wise-hosting" element={<TaxWiseHosting />} />
        <Route path="/small-business" element={<SmallBusiness />} />
        <Route path="/law-firm" element={<LawFirm />} />
        <Route path="/law-firm-hosting" element={<LawFirm />} />
        <Route path="/virtual-desktop" element={<HostedVirtualDesktop />} />
        <Route path="/managed-it-services" element={<ManagedITServices />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/price" element={<Price />} />
        <Route path="/free-trial" element={<FreeTrial />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/thankyou" element={<ThankYouPage />} />
        <Route path="/quickbooks-canada" element={<QuickBooksCanada />} />
        <Route path="/quickbooks-accountant" element={<QuickBooksAccountant />} />
      </Routes>
      <Footer />
    </BrowserRouter>

  );
}

export default App;
