import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaTools, FaExchangeAlt, FaHandshake, FaCloud, FaComments, FaDatabase, FaServer, FaHeadset, FaCogs, FaUserCheck, FaDollarSign, FaUserClock, FaLayerGroup, FaShieldAlt, FaLaptop, FaUserFriends, FaLock, } from 'react-icons/fa';
import { Helmet } from 'react-helmet';



export default function TaxHosting() {

    const TaxHero = () => {
        const { scrollY } = useScroll();
        const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
        const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
        const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);
        const yImage = useTransform(scrollY, [0, 1000], [0, 100]);

        return (
            <div className="relative h-[500px] md:h-[600px] bg-[url('https://concisenext.com/public_image/webp/electronics-6801339_1280.webp')] bg-center bg-no-repeat bg-cover overflow-hidden">
                {/* Background Circles Effect */}
                <div className="absolute flex items-center justify-center">
                    <motion.div
                        className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
                        style={{ scale: scale1 }}
                    />
                    <motion.div
                        className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
                        style={{ scale: scale2 }}
                    />
                    <motion.div
                        className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
                        style={{ scale: scale3 }}
                    />
                </div>

                {/* Hero Content */}
                <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-white text-center px-4 md:px-6">
                    <motion.h1
                        className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2 md:mb-4"
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Tax Software Hosting With <span className='text-blue-600 font-bold'>ConciseNext</span>
                    </motion.h1>
                    <motion.p
                        className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
                    >
                        Optimize your tax practice with secure, remote access to your favorite tax software. With ConciseNext’s cloud hosting, enjoy seamless collaboration, automated backups, and top-notch security for your clients' sensitive data.
                    </motion.p>
                    <motion.a
                        href="/free-trial"
                        className="px-4 py-2 md:px-6 md:py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.5 }}
                    >
                        Start Your 7 Day Free Trial
                    </motion.a>
                </div>

                {/* Parallax Image */}
                {/* <motion.img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSf0J11mGCr4qq2ijT_M-BkcRpwBC9Hw_JKGP3_gcRakZAv1oSAWrooX9aS9uVTUX9gFF4&usqp=CAU"
                    alt="QuickBooks"
                    className="absolute bottom-0 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 right-0 transform translate-y-8 md:translate-y-16 sm:translate-y-0"
                    style={{ y: yImage }}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1, ease: "easeOut" }}
                /> */}
            </div>
        );
    };



    const TaxIntroSection = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView && window.innerWidth > 768) {
                controls.start('visible');
            }
        }, [controls, inView]);

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto p-6 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    TaxWise Hosting - ConciseNext
                </h2>

                <div className="flex flex-col md:flex-row gap-6 justify-center items-center">
                    <img
                        src="https://concisenext.com/public_image/Best-tax-suite-Drake-Tax-Software-Hosting.webp"
                        alt="QuickBooks License"
                        className="rounded-lg shadow-lg w-[400px] h-[auto]"
                    />
                    <p className="text-lg text-gray-700 leading-relaxed text-center md:text-left">
                        Maximize your tax preparation efficiency with ConciseNext’s TaxWise Hosting services. Designed for tax professionals and accounting firms of all sizes, our cloud-based hosting solution provides secure, remote access to TaxWise Software from any device, at any time. Whether you’re managing complex tax returns or collaborating with team members, ConciseNext offers the flexibility, reliability, and security you need to streamline your workflow.
                    </p>
                </div>

                <h3 className="text-3xl pt-8 font-bold text-gray-800 text-center md:text-left">
                    Why Choose ConciseNext for TaxWise Hosting?
                </h3>

                <ul className="space-y-8">
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaLaptop className="w-8 h-8 text-blue-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Remote Access Anytime, Anywhere</h4>
                            <p className="text-lg text-gray-700">
                                With ConciseNext’s TaxWise Hosting, you can access your TaxWise software from any internet-connected device, whether you’re in the office, at home, or on the go. This mobility allows you to work more efficiently, no matter your location.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaUserFriends className="w-8 h-8 text-green-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Multi-User Collaboration</h4>
                            <p className="text-lg text-gray-700">
                                Enable your team to work on the same tax returns simultaneously. ConciseNext’s cloud hosting supports real-time collaboration, improving accuracy and speeding up the tax preparation process.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaShieldAlt className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Enterprise-Grade Security</h4>
                            <p className="text-lg text-gray-700">
                                Security is our top priority. ConciseNext uses SOC-certified data centers, multi-layered encryption, firewalls, and routine security audits to ensure your client data is protected at all times.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaDollarSign className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Cost-Effective Hosting</h4>
                            <p className="text-lg text-gray-700">
                                Reduce your IT overhead by eliminating the need for on-site servers and expensive hardware. With ConciseNext’s scalable solutions, you only pay for the resources you need, helping you save money while optimizing efficiency.
                            </p>
                        </div>
                    </li>

                </ul>
            </motion.div>
        );
    };

    const TaxBenefits = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.3 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto pt-8 p-6 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    Key Benefits of Hosting TaxWise with ConciseNext
                </h2>

                {/* License Options */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-20">
                    {[
                        {
                            title: '99.9% Uptime',
                            description:
                                'With our reliable cloud infrastructure, your TaxWise Software will always be available when you need it. Minimize downtime and maintain productivity even during peak tax season.',
                            imageUrl:
                                'https://concisenext.com/public_image/960x0.webp',
                            link: '/quickbooks-pro-hosting',
                        },
                        {
                            title: 'Fully Managed Cloud Environment',
                            description:
                                'Let us handle all aspects of server maintenance, software updates, backups, and security patches. Our fully managed hosting service ensures that your TaxWise environment performs optimally at all times.',
                            imageUrl:
                                'https://concisenext.com/public_image/hr-learn20-gpstrategies-thumb.webp',
                            link: '#',
                        },

                        {
                            title: '24/7 Technical Support',
                            description:
                                'Our dedicated support team is available around the clock to assist you with any technical issues, ensuring a seamless and hassle-free hosting experience.',
                            imageUrl:
                                'https://concisenext.com/public_image/best-online-tech-support-services.webp',
                            link: '#',
                        },
                        {
                            title: 'Seamless Migration',
                            description:
                                'Transitioning your TaxWise software to the cloud with ConciseNext is quick and easy. Our migration experts will guide you through the process with minimal downtime or disruption to your workflow.',
                            imageUrl:
                                'https://concisenext.com/public_image/technology-driven-workplace-transformation-and-how-to-go-about-it.webp',
                            link: '#',
                        },
                    ].map((license, index) => (
                        <motion.div
                            key={index}
                            whileHover={{ scale: 1.05 }}
                            className="bg-white rounded-lg shadow-lg p-5 flex flex-col items-center text-start space-y-4 transition-transform duration-300"
                        >
                            <img
                                src={license.imageUrl}
                                alt={license.title}
                                className="rounded-lg shadow-lg w-full h-48 object-cover"
                            />
                            <h4 className="text-xl font-semibold text-gray-800">
                                {license.title}
                            </h4>
                            <p className="text-gray-600 pb-10">{license.description}</p>

                        </motion.div>
                    ))}
                </div>
            </motion.div>
        );
    };


    const TaxHostingSolutions = () => {
        const controls = useAnimation();
        const { ref, inView } = useInView({ threshold: 0.2 });

        React.useEffect(() => {
            if (inView) {
                controls.start('visible');
            } else {
                controls.start('hidden');
            }
        }, [controls, inView]);

        const fadeInVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
        };
        const isLargeScreen = window.innerWidth >= 768;

        return (
            <div className="bg-gray-100 text-gray-800 py-16">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl font-bold text-center mb-12">TaxWise Hosting Plans</h2>

                    <div ref={ref} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        <motion.div
                            className="p-8 rounded-lg shadow-lg bg-white border-t-4 border-blue-500"
                            initial="hidden"
                            animate={isLargeScreen ? controls : 'visible'}
                            variants={fadeInVariants}
                        >
                            <div className="flex items-center mb-4">
                                <FaCloud className="text-blue-500 text-4xl mr-4" />
                                <h3 className="text-3xl font-semibold">Single-User Hosting</h3>
                            </div>
                            <p className="text-lg mb-6">
                                Designed for individual tax professionals who need secure and remote access to TaxWise without the hassle of managing IT infrastructure.
                            </p>
                            <button className="flex items-center text-white  bg-button border-red-500 px-10 py-3 hover:bg-red-700 hover:text-white transition-colors rounded-md">
                                Know More
                            </button>
                        </motion.div>

                        <motion.div
                            className="p-8 rounded-lg shadow-lg bg-white border-t-4 border-green-500"
                            initial="hidden"
                            animate={controls}
                            variants={fadeInVariants}
                        >
                            <div className="flex items-center mb-4">
                                <FaServer className="text-green-500 text-4xl mr-4" />
                                <h3 className="text-3xl font-semibold">Multi-User Hosting</h3>
                            </div>
                            <p className="text-lg mb-6">
                                Ideal for small to mid-sized accounting firms, allowing multiple users to collaborate on tax returns in real-time for faster results.
                            </p>
                            <button className="flex items-center text-white  bg-button border-red-500 px-10 py-3 rounded-md hover:bg-red-500 hover:text-white transition-colors">
                                Know More
                            </button>
                        </motion.div>

                        <motion.div
                            className="p-8 rounded-lg shadow-lg bg-white border-t-4 border-red-500"
                            initial="hidden"
                            animate={controls}
                            variants={fadeInVariants}
                        >
                            <div className="flex items-center mb-4">
                                <FaDatabase className="text-red-500 text-4xl mr-4" />
                                <h3 className="text-3xl font-semibold">Enterprise-Level Hosting</h3>
                            </div>
                            <p className="text-lg mb-6">
                                Tailored for larger firms with advanced needs, offering robust security, high availability, and scalable resources to accommodate large volumes of users and tax returns.
                            </p>
                            <button className="flex items-center text-white  bg-button border-red-500 px-10 py-3 rounded-lg hover:bg-red-500 hover:text-white transition-colors">
                                Know More
                            </button>
                        </motion.div>
                    </div>
                </div>
            </div>
        );
    };



    const TaxHostingWorks = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;
        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-6xl mx-auto p-8 pt-8 space-y-12 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg shadow-lg"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">
                    How ConciseNext’s TaxWise Hosting Works
                </h2>

                <div className="relative">
                    <div className="absolute left-1/2 top-0 transform -translate-x-1/2 h-full border-l-2 border-dashed border-gray-300" />
                    <div className="space-y-8">
                        {[
                            {
                                step: 'Consultation',
                                description: 'We assess your specific needs and recommend the best hosting solution for your TaxWise environment.',
                                icon: <FaComments className="w-10 h-10 text-blue-500" />,
                            },
                            {
                                step: 'Smooth Migration',
                                description: 'Our team ensures a secure and seamless migration of your TaxWise Software and data to the cloud, minimizing any downtime.',
                                icon: <FaExchangeAlt className="w-10 h-10 text-green-500" />,
                            },
                            {
                                step: 'Cloud Access',
                                description: 'Once hosted, you can access TaxWise remotely from any device with an internet connection, providing you with the flexibility to work from anywhere.',
                                icon: <FaCloud className="w-10 h-10 text-purple-500" />,
                            },
                            {
                                step: 'Ongoing Management',
                                description: 'We take care of everything, from software updates to data backups and security patches, so you can focus on your tax preparation tasks without worrying about IT management.',
                                icon: <FaHeadset className="w-10 h-10 text-red-500" />,
                            },
                        ].map((step, index) => (
                            <div key={index} className="relative bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center space-y-4">
                                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 flex items-center justify-center bg-white rounded-full border-4 border-gray-200">
                                    {step.icon}
                                </div>
                                <div className="mt-12">
                                    <h3 className="text-2xl font-semibold text-gray-800">{step.step}</h3>
                                    <p className="text-lg text-gray-600 mt-2">{step.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </motion.div>
        );
    };


    const WhyChooseConciseNext = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

        useEffect(() => {
            if (inView) {
                controls.start("visible");
            }
        }, [controls, inView]);

        const cardVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    duration: 0.6,
                    ease: "easeOut",
                    when: "beforeChildren",
                    staggerChildren: 0.3,
                },
            },
        };

        const iconVariants = {
            hidden: { scale: 0 },
            visible: {
                scale: 1,
                transition: {
                    duration: 0.5,
                    ease: "backOut",
                },
            },
        };

        return (
            <div className="bg-gray-50 py-5">
                <div className="max-w-7xl mx-auto px-6 sm:px-8">
                    <motion.h2
                        className="text-4xl font-bold text-center text-gray-800 mb-12"
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Why Tax Professionals Trust ConciseNext?
                    </motion.h2>

                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={cardVariants}
                        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8"
                    >
                        {[
                            {
                                title: "Expert Hosting Services",
                                description:
                                    "With years of experience in hosting tax software, ConciseNext understands the unique requirements of tax professionals and accounting firms. We offer tailored solutions to meet your specific needs.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-blue-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaShieldAlt size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Scalable Hosting Plans",
                                description:
                                    "Whether you’re a solo practitioner or part of a large CPA firm, ConciseNext offers hosting plans that can scale with your business as it grows.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-green-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaLayerGroup size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Robust Security Features",
                                description:
                                    "We provide advanced security measures, including encrypted data storage, multi-factor authentication, and real-time monitoring to protect sensitive tax data.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaTools size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Comprehensive IT Management",
                                description:
                                    "Let us handle the technical complexities of hosting your TaxWise software so you can concentrate on preparing and filing returns efficiently.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaTools size={48} />
                                    </motion.div>
                                ),
                            },
                        ].map((feature, index) => (
                            <motion.div
                                key={index}
                                className="bg-white p-8 rounded-lg shadow-lg text-start flex flex-col items-center"
                            >
                                <div className="flex-shrink-0">{feature.icon}</div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                                    {feature.title}
                                </h3>
                                <p className="text-gray-600">{feature.description}</p>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </div>
        );
    };



    const TaxSoftwareVersion = () => {
        const containerVariants = {
            hidden: { opacity: 0, y: 20 },
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    staggerChildren: 0.3,
                    duration: 0.5,
                    ease: 'easeInOut',
                },
            },
        };

        const itemVariants = {
            hidden: { opacity: 0, y: 10 },
            visible: { opacity: 1, y: 0 },
        };

        const softwareList = [
            { name: 'Drake Tax Software', icon: FaDatabase },
            { name: 'Lacerte Tax Software', icon: FaCloud },
            { name: 'ProSeries Tax Software', icon: FaLock },
            { name: 'UltraTax CS', icon: FaTools },
            { name: 'ProSystem fx', icon: FaCloud },
        ];

        return (
            <div className="relative bg-blue-50 py-16 px-6">
                {/* Background Image */}
                <div className="absolute inset-0 bg-[url('https://concisenext.com/public_image/ai-generated-8799924_1280.webp')] bg-cover bg-center opacity-20"></div>

                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={containerVariants}
                    className="relative max-w-6xl mx-auto text-center bg-white p-8 rounded-lg shadow-lg"
                >
                    {/* Title Section */}
                    <motion.h2
                        variants={itemVariants}
                        className="text-4xl font-bold text-blue-900 mb-8"
                    >
                        Additional Tax Software Hosting Solutions
                    </motion.h2>

                    {/* List of Tax Software with Icons */}
                    <motion.ul variants={containerVariants} className="space-y-4 text-left">
                        {softwareList.map((software, index) => (
                            <motion.li
                                key={index}
                                variants={itemVariants}
                                className="flex items-center text-lg text-blue-800 font-medium"
                            >
                                <software.icon className="text-blue-600 w-8 h-8 mr-4" />
                                {software.name}
                            </motion.li>
                        ))}
                    </motion.ul>

                    {/* Custom Hosting Solutions Section */}
                    <motion.p
                        variants={itemVariants}
                        className="mt-8 text-blue-700 text-lg"
                    >
                        If you need custom hosting solutions for any other tax software,{' '}
                        <span className="font-semibold underline hover:text-blue-900 cursor-pointer">
                            contact us
                        </span>{' '}
                        for personalized support.
                    </motion.p>

                    {/* Divider */}
                    <div className="mt-12">
                        <hr className="border-blue-300 mb-8" />

                        {/* TaxWise Hosting Section with Image */}
                        <motion.div variants={containerVariants} className="text-left">
                            <motion.h3
                                variants={itemVariants}
                                className="text-3xl font-bold text-blue-900 mb-4"
                            >
                                Simplify Your Tax Preparation with ConciseNext’s TaxWise Hosting
                            </motion.h3>
                            <div className="flex flex-col md:flex-row md:items-center">
                                <motion.p
                                    variants={itemVariants}
                                    className="text-lg text-blue-700 leading-relaxed md:w-1/2"
                                >
                                    With ConciseNext’s TaxWise Hosting, you gain the flexibility to
                                    work from anywhere, collaborate with your team, and ensure your
                                    clients’ data is secure. Our fully managed cloud hosting services
                                    are designed to help you stay productive and deliver results
                                    during the busiest times of the year.
                                </motion.p>
                                {/* Image with transition */}
                                <motion.img
                                    src="https://concisenext.com/public_image/ai-generated-8799924_1280.webp"
                                    alt="TaxWise Hosting"
                                    className="rounded-lg shadow-lg w-full md:w-1/2 mt-6 md:mt-0 md:ml-8"
                                    initial={{ opacity: 0, scale: 0.9 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.5 }}
                                />
                            </div>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        );
    };




    const ContactUs = () => {
        return (
            <section className="relative bg-gray-100 py-6 px-4 sm:px-6 lg:px-8 overflow-hidden">
                <div className="absolute inset-0">
                    <svg className="absolute top-0 right-0 transform translate-x-1/2 translate-y-1/4 -z-10" width="404" height="404" fill="none" viewBox="0 0 404 404">
                        <defs>
                            <pattern id="pattern1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-indigo-600" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="100%" height="100%" fill="url(#pattern1)" />
                    </svg>
                </div>
                <div className="relative max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                    <motion.h2
                        className="text-3xl font-extrabold text-gray-900 mb-6"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: 'easeOut' }}
                    >
                        Contact Us
                    </motion.h2>
                    <div className="flex flex-col items-center">
                        <motion.div
                            className="text-gray-800 mb-4"
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.4 }}
                        >
                            <p className="text-lg font-semibold">📞 Call us: +1 (817) 803-6797</p>
                            <p className="text-lg font-semibold">📧 Email us: <a href="mailto:sales@ConciseNext.com" className="text-indigo-600 hover:underline">sales@ConciseNext.com</a></p>
                        </motion.div>
                        <motion.p
                            className="text-gray-500"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.6 }}
                        >
                            ConciseNext – The trusted solution for cloud hosting TaxWise Software
                        </motion.p>
                    </div>
                </div>
            </section>
        );
    };




    return (
        <div>
            <Helmet>
                {/* Document Title */}
                <title>Drake Tax Software Hosting Solutions | Drake Tax Hosting Services</title>

                {/* Meta Tags */}
                <meta name="title" content="Drake Tax Software Hosting Solutions | Drake Tax Hosting Services" />
                <meta name="focus_keyword" content="Tax Software Hosting" />
                <meta name="description" content="Secure your tax preparation with Drake Tax Cloud Hosting solutions. Concisenext ensures secure, efficient access and enhanced performance for all your tax hosting needs." />
                <meta name="keywords" content="concisenext, quickbook, sage, drake, accounting software, business management, financial solutions, bookkeeping tools, invoicing software, tax preparation, payroll management" />
                <meta name="robots" content="index, follow" />

                {/* Canonical Link */}
                <link rel="canonical" href={window.location.href} />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="ConciseNext: Your Cloud Application Hosting Solution" />
                <meta property="og:description" content="Discover seamless cloud application hosting for QuickBooks, Sage, Drake, and more. Reliable, secure, and tailored for your business needs." />
                <meta property="og:image" content="https://concisenext.com/public_image/webp/logocolor.481479ea418411f3e450.webp" />
                <meta property="og:url" content={window.location.href} />
            </Helmet>

            <TaxHero />
            <TaxIntroSection />
            <TaxBenefits />
            <TaxHostingSolutions />
            <TaxHostingWorks />
            <WhyChooseConciseNext />
            <TaxSoftwareVersion />
            <ContactUs />


        </div>
    )

}