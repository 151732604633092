import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';


const RequestTrialForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        numberOfUsers: '',
        hearAboutUs: '',
        hostingName: '',
        application: '',
        serialKey: '',
        phoneCountryCode: '+1',
        receiveUpdates: false,
    });

    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const navigate = useNavigate();


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const validate = () => {
        const newErrors = {};
        for (const key in formData) {
            // Check if the field is empty and it's not a checkbox
            if (!formData[key] && key !== 'phoneCountryCode' && key !== 'receiveUpdates') {
                newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')} is required`;
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Returns true if no errors
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            const emailData = {
                ...formData,
                receiveUpdates: formData.receiveUpdates ? 'Yes' : 'No', // Convert to 'Yes' or 'No'
            };
            emailjs.send('service_bp00fd6', 'template_z8hg25w', emailData, 'dKZYWeqy3LxuG5n_D')
                .then((response) => {
                    console.log('SUCCESS:', response);
                    navigate('/thankyou');

                    setMessage('Your query has been submitted successfully.');
                    setFormData({
                        name: '',
                        email: '',
                        phone: '',
                        numberOfUsers: '',
                        hearAboutUs: '',
                        hostingName: '',
                        application: '',
                        serialKey: '',
                        phoneCountryCode: '+1',
                        receiveUpdates: false,
                    });
                    setErrors({});
                }, (err) => {
                    console.log('FAILED:', err);
                    setMessage('There was an error submitting your query. Please try again.');
                });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {message && <p className="mb-4 text-lg text-success font-semibold">{message}</p>}

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-600 mb-1">Name</label>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300" required />
                    {errors.name && <p className="text-red-600 text-xs">{errors.name}</p>}
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-600 mb-1">Email</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300" required />
                    {errors.email && <p className="text-red-600 text-xs">{errors.email}</p>}
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-600 mb-1">Phone Number</label>
                    <div className="flex">
                        <select name="phoneCountryCode" value={formData.phoneCountryCode} onChange={handleChange} className="px-4 py-2 border border-gray-300 rounded-l-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300" style={{ width: "120px" }} required>
                            <option value="+44">+44</option>
                            <option value="+1">+1</option>
                            <option value="+91">+91</option>
                            <option value="+49">+49</option>
                            <option value="+41">+41</option>
                            <option value="+852">+852</option>
                            <option value="+55">+55</option>
                        </select>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-r-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
                            placeholder="Phone Number"
                            required
                        />
                    </div>
                    {errors.phone && <p className="text-red-600 text-xs">{errors.phone}</p>}
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-600 mb-1">Number of Users</label>
                    <input type="number" name="numberOfUsers" value={formData.numberOfUsers} onChange={handleChange} className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300" required />
                    {errors.numberOfUsers && <p className="text-red-600 text-xs">{errors.numberOfUsers}</p>}
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-600 mb-1">How Did You Hear About Us?</label>
                    <select name="hearAboutUs" value={formData.hearAboutUs} onChange={handleChange} className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300" required>
                        <option value="">Select an option</option>
                        <option value="google">Google ads</option>
                        <option value="friend">Email / Newsletters</option>
                        <option value="linkedin">LinkedIn</option>
                        <option value="facebook">Facebook</option>
                        <option value="twitter">Twitter</option>
                        <option value="referral">Referred by a Friend</option>
                        <option value="other">Other</option>
                    </select>
                    {errors.hearAboutUs && <p className="text-red-600 text-xs">{errors.hearAboutUs}</p>}
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-600 mb-1">Desired Hosting Name</label>
                    <input type="text" name="hostingName" value={formData.hostingName} onChange={handleChange} className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300" required />
                    {errors.hostingName && <p className="text-red-600 text-xs">{errors.hostingName}</p>}
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-600 mb-1">Select Application</label>
                    <select name="application" value={formData.application} onChange={handleChange} className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300" required>
                        <option value="">Select Application</option>
                        <option value="QuickBooks Pro">QuickBooks Pro</option>
                        <option value="QuickBooks Premier">QuickBooks Premier</option>
                        <option value="QuickBooks POS">QuickBooks POS</option>
                        <option value="QuickBooks Enterprise">QuickBooks Enterprise</option>
                        <option value="Sage 50 Hosting">Sage 50 Hosting</option>
                        <option value="Sage 100 Hosting">Sage 100 Hosting</option>
                        <option value="Sage 300 Hosting">Sage 300 Hosting</option>
                        <option value="Sage 500 Hosting">Sage 500 Hosting</option>
                        <option value="Drake Hosting">Drake Hosting</option>
                        <option value="ProSeries Hosting">ProSeries Hosting</option>
                        <option value="UltraTax Hosting">UltraTax Hosting</option>
                        <option value="Lacerte Hosting">Lacerte Hosting</option>
                        <option value="TaxWise Hosting">TaxWise Hosting</option>
                    </select>
                    {errors.application && <p className="text-red-600 text-xs">{errors.application}</p>}
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-600 mb-1">Application Serial Key / Product Key</label>
                    <input type="text" name="serialKey" value={formData.serialKey} onChange={handleChange} className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300" required />
                    {errors.serialKey && <p className="text-red-600 text-xs">{errors.serialKey}</p>}
                </div>
            </div>
            <div className="mb-2 flex items-start">
                <input type="checkbox" name="receiveUpdates" checked={formData.receiveUpdates} onChange={handleChange} className="mt-1 mr-2" />
                <label className="block text-sm font-medium text-gray-600">
                    I'd like to receive useful industry news, trends, and updates directly to my inbox from ConciseNext.
                </label>
            </div>
            <div className='mb-3'>
                {errors.receiveUpdates && <p className="text-red-600 text-xs">{errors.receiveUpdates}</p>}
            </div>
            <button
                type="submit"
                className="w-full bg-[#35436a] text-white py-2 rounded-md font-medium transition-all hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300"
            >
                Request Free Trial
            </button>
        </form>
    );
};

export default RequestTrialForm;
