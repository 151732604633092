import React from 'react';
import { FaCloud, FaLock, FaHeadset, FaHandsHelping, FaRocket, FaHandshake } from 'react-icons/fa';
import "../styles/aboutus.css"
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const AboutUs = () => {

    const { scrollY } = useScroll();
    const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
    const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
    const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);

    return (
        <>
            <Helmet>
                {/* Document Title */}
                <title>About ConciseNext | Expert Cloud Hosting & IT Services</title>

                {/* Meta Tags */}
                <meta name="title" content="About ConciseNext | Expert Cloud Hosting & IT Services" />
                <meta name="focus_keyword" content="About Us" />
                <meta name="description" content="Learn about ConciseNext, your trusted partner for secure cloud hosting and IT services. Discover our mission, expertise, and commitment to your success." />
                <meta name="keywords" content="concisenext, quickbook, sage, drake, accounting software, business management, financial solutions, bookkeeping tools, invoicing software, tax preparation, payroll management" />
                <meta name="robots" content="index, follow" />

                {/* Canonical Link */}
                <link rel="canonical" href={window.location.href} />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="ConciseNext: Your Cloud Application Hosting Solution" />
                <meta property="og:description" content="Discover seamless cloud application hosting for QuickBooks, Sage, Drake, and more. Reliable, secure, and tailored for your business needs." />
                <meta property="og:image" content="https://concisenext.com/public_image/webp/logocolor.481479ea418411f3e450.webp" />
                <meta property="og:url" content={window.location.href} />
            </Helmet>
            <div className="bg-gray-50 text-gray-800">
                {/* Hero Section */}

                <div className="relative  bg-[url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0DeTPLl0yCKSvhMMrFs0EsHMgif2-2IlJrQ&s')] bg-center h-[500px] md:h-[600px] bg-no-repeat bg-cover overflow-hidden">
                    {/* Background Circles Effect */}
                    <div className="absolute flex items-center justify-center">
                        <motion.div
                            className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
                            style={{ scale: scale1 }}
                        />
                        <motion.div
                            className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
                            style={{ scale: scale2 }}
                        />
                        <motion.div
                            className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
                            style={{ scale: scale3 }}
                        />
                    </div>

                    {/* Hero Content */}
                    <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-white text-center px-4 md:px-6">
                        <motion.h1
                            className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2 md:mb-4"
                            initial={{ y: -50, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ duration: 0.8, ease: "easeOut" }}
                        >
                            About Us
                        </motion.h1>
                        <motion.p
                            className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
                            initial={{ y: 50, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
                        >
                            At ConciseNext Solutions, we are dedicated to providing top-notch QuickBooks Hosting services tailored to meet the needs of businesses of all sizes. Our mission is to empower organizations with reliable, secure, and efficient cloud solutions that enhance productivity, collaboration, and financial management.
                        </motion.p>

                    </div>

                </div>


                {/* Who We Are Section */}
                <section className="flex flex-col md:flex-row items-center py-12 px-8 lg:px-36 bg-white overflow-hidden">

                    <div className="md:w-1/2 w-full mb-8 md:mb-0 animate-slide-in-left">
                        <img
                            src="https://ostechnix.com/wp-content/uploads/2021/12/Cloud-Computing.png.webp"
                            alt="Who We Are"
                            className="w-[600px]  object-cover rounded-xl shadow-lg transform transition-transform duration-500 hover:scale-105"
                        />
                    </div>

                    {/* Right Text */}
                    <div className="md:w-1/2 w-full text-center md:text-left animate-fade-in-right">
                        <h2 className="text-3xl font-semibold mb-6">Who We Are</h2>
                        <p className="text-lg mb-4 text-start">
                            Founded by a team of experienced IT professionals, ConciseNext Solutions is built on a foundation of innovation and excellence. Our expertise spans various industries, enabling us to understand the unique challenges our clients face and provide solutions that drive success.
                        </p>
                        <p className="text-lg text-start">
                            We believe in harnessing the power of technology to simplify complex processes, enabling businesses to focus on what they do best.
                        </p>
                    </div>
                </section>


                {/* Our Vision Section */}

                <section className="flex flex-col md:flex-row items-center py-12 px-8 lg:px-36 bg-white overflow-hidden">

                    <div className="md:w-1/2 w-full text-center mr-20 md:text-left animate-fade-in-left">
                        <h2 className="text-3xl font-semibold mb-6">Our Vision</h2>
                        <p className="text-lg mb-4 text-start">
                            Our vision is to be a leading provider of cloud-based financial solutions that transform the way businesses manage their accounting and financial processes. We aim to create an environment where our clients can thrive, ensuring they have access to the tools and support needed for success in a competitive landscape.
                        </p>

                    </div>

                    <div className="md:w-1/2 w-full mb-8 md:mb-0  animate-slide-in-right">
                        <img
                            src="https://img.freepik.com/premium-photo/promotional-banner-cloud-hosting-services-with-pricing-features_1327465-20666.jpg"
                            alt="Who We Are"
                            className="w-[600px]  object-cover rounded-xl shadow-lg transform transition-transform duration-500 hover:scale-105"
                        />
                    </div>
                </section>



                {/* Our Values Section */}
                <section className="py-12 px-8">
                    <h2 className="text-3xl font-semibold text-center mb-8">Our Values</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 max-w-7xl mx-auto">
                        <div className="text-center animate-fade-in-down hover:scale-105 transition-transform duration-300">
                            <FaHandshake className="mx-auto text-7xl text-blue-600 mb-4" />
                            <h3 className="text-xl font-semibold text-center">Customer-Centric Approach</h3>
                            <p className="mt-2 text-lg text-center">
                                Our clients are at the heart of everything we do. We strive to build long-lasting relationships based on trust, transparency, and exceptional service.
                            </p>
                        </div>
                        <div className="text-center animate-fade-in-down hover:scale-105 transition-transform duration-300">
                            <FaLock className="mx-auto text-7xl text-blue-600 mb-4" />
                            <h3 className="text-xl font-semibold text-center">Integrity</h3>
                            <p className="mt-2 text-lg text-center">
                                We conduct our business with honesty and integrity, ensuring that we deliver on our promises and maintain the highest ethical standards.
                            </p>
                        </div>
                        <div className="text-center animate-fade-in-down hover:scale-105 transition-transform duration-300">
                            <FaRocket className="mx-auto text-7xl text-blue-600 mb-4" />
                            <h3 className="text-xl font-semibold text-center">Innovation</h3>
                            <p className="mt-2 text-lg text-center">
                                We embrace change and continuously seek innovative solutions to enhance our services and meet the evolving needs of our clients.
                            </p>
                        </div>
                        <div className="text-center animate-fade-in-down hover:scale-105 transition-transform duration-300">
                            <FaHandsHelping className="mx-auto text-7xl text-blue-600 mb-4" />
                            <h3 className="text-xl font-semibold text-center">Collaboration</h3>
                            <p className="mt-2 text-lg text-center">
                                We believe in the power of teamwork, both within our organization and with our clients. By working together, we achieve better results.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Our Services Section */}
                <section className="bg-gray-100 py-12 px-8">
                    <h2 className="text-3xl font-semibold text-center mb-8">Our Services</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
                        <div className="text-center animate-fade-in-left">
                            <FaCloud className="mx-auto text-7xl text-blue-600 mb-4" />
                            <h3 className="text-xl font-semibold">QuickBooks Cloud Hosting</h3>
                            <p className="mt-2 text-lg">
                                Access your QuickBooks software from anywhere, anytime, with our secure cloud hosting solutions.
                            </p>
                        </div>
                        <div className="text-center animate-fade-in-up">
                            <FaLock className="mx-auto text-7xl text-blue-600 mb-4" />
                            <h3 className="text-xl font-semibold">Data Security and Backup</h3>
                            <p className="mt-2 text-lg">
                                We prioritize the security of your financial data, implementing advanced measures to protect it from threats and ensuring regular backups
                            </p>
                        </div>
                        <div className="text-center animate-fade-in-right">
                            <FaHeadset className="mx-auto text-7xl text-blue-600 mb-4" />
                            <h3 className="text-xl font-semibold">24/7 Technical Support</h3>
                            <p className="mt-2 text-lg">
                                Our knowledgeable support team is available around the clock to assist you with any issues or questions you may have.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Why Choose Us Section */}

                <section className="py-12 px-8 bg-gray-100">
                    <div className="max-w-7xl mx-auto text-center">
                        <h2 className="text-3xl font-semibold mb-8">Why Choose Us?</h2>

                        {/* Content Grid */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                            {/* Expertise Card */}
                            <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 animate-fade-in-up">
                                <div className="flex items-center justify-center mb-4">
                                    {/* Expertise Icon */}
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-12 h-12 text-blue-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 14l9-5-9-5-9 5 9 5z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 14l6.16-3.422A12.083 12.083 0 0121 16.5a12.083 12.083 0 01-2.84 7.078L12 19.5 3.84 23.578A12.083 12.083 0 011 16.5c0-2.61 1.012-5.2 2.84-7.078L12 14z" />
                                    </svg>
                                </div>
                                <h3 className="text-xl font-semibold mb-2">Expertise</h3>
                                <p className="text-gray-600">
                                    Our team consists of professionals with extensive experience in IT and financial management, ensuring that you receive the highest level of service.
                                </p>
                            </div>

                            {/* Scalability Card */}
                            <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 animate-fade-in-up delay-150">
                                <div className="flex items-center justify-center mb-4">
                                    {/* Scalability Icon */}
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-12 h-12 text-blue-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                                    </svg>
                                </div>
                                <h3 className="text-xl font-semibold mb-2">Scalability</h3>
                                <p className="text-gray-600">
                                    We offer flexible solutions that grow with your business, allowing you to add users and features as needed.
                                </p>
                            </div>

                            {/* Affordability Card */}
                            <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 animate-fade-in-up delay-300">
                                <div className="flex items-center justify-center mb-4">
                                    {/* Affordability Icon */}
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-12 h-12 text-blue-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 1.343-3 3h6c0-1.657-1.343-3-3-3zM6 16c0-3.866 3.134-7 7-7s7 3.134 7 7H6z" />
                                    </svg>
                                </div>
                                <h3 className="text-xl font-semibold mb-2">Affordability</h3>
                                <p className="text-gray-600">
                                    Our competitive pricing structures make it easy for businesses of all sizes to access premium QuickBooks Hosting services without breaking the bank.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Join Us on Your Journey Section */}
                <section className="py-12 px-8 ">
                    <div className="max-w-7xl mx-auto text-center">
                        <h2 className="text-4xl font-bold mb-6">Join Us on Your Journey</h2>
                        <p className="text-lg mb-8">
                            At ConciseNext Solutions, we are committed to helping you achieve your business goals through innovative technology solutions. Whether you're a small business looking to streamline your accounting processes or a large organization in need of robust hosting services, we are here to help.
                        </p>

                        <div className="flex justify-center">
                            <Link to={"/contact-us"}
                                href="#contact"
                                className="bg-yellow-400 hover:bg-yellow-500 text-gray-800 font-semibold py-3 px-6 rounded-full shadow-lg transition duration-300"
                            >
                                Get Started
                            </Link>
                        </div>
                    </div>
                </section>


                {/* Get in Touch Section */}
                <section className="py-16 px-8 bg-gradient-to-r from-blue-400 to-blue-600 text-white">
                    <div className="max-w-7xl mx-auto text-center">
                        <h2 className="text-4xl font-bold mb-6">Get in Touch</h2>
                        <p className="text-lg mb-8">
                            Ready to take your financial management to the next level? Contact us today to learn more about our QuickBooks Hosting services and how we can assist you in achieving your business objectives.
                        </p>

                        <div className="flex flex-col items-center mb-6">
                            {/* Contact Information Card */}
                            <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
                                <h3 className="text-2xl font-semibold mb-4 text-gray-800">Contact Information</h3>
                                <div className="mb-4">
                                    <p className="flex items-center text-gray-700">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5 text-green-500 mr-2">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 7h18M3 12h18m-6 5h6" />
                                        </svg>
                                        <span>Email: <a href="mailto:xyzabc@gmail.com" className="text-green-500 hover:underline">support@concisenext.com</a></span>
                                    </p>
                                </div>
                                <div className="mb-4">
                                    <p className="flex items-center text-gray-700">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5 text-green-500 mr-2">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                                        </svg>
                                        <span>Phone: <a href="tel:+18178036797" className="text-green-500 hover:underline">+1 (817) 803-6797</a></span>
                                    </p>
                                </div>
                                <div className="flex items-center text-gray-700">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5 text-green-500 mr-2">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                                    </svg>
                                    <span>Address: <span className="text-gray-600">CONCISENEXT INFOTECH LLC<br />
                                        5900, BALCONES DRIVE, STE. 100;<br />
                                        AUSTIN, TEXAS-78731</span></span>
                                </div>
                            </div>
                        </div>

                        <p className="text-lg max-w-2xl mx-auto">
                            Thank you for considering ConciseNext Solutions as your trusted partner in QuickBooks Hosting! We look forward to working with you.
                        </p>
                    </div>
                </section>





            </div >
        </>
    );
};

export default AboutUs;
