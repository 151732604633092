import React, { useEffect, useState } from 'react';
import {
    FaCheckCircle, FaHandshake, FaLightbulb, FaLock, FaHeadset, FaDollarSign, FaEnvelope,
    FaPhone, FaMapMarkerAlt, FaFacebook, FaLinkedin, FaInstagram, FaYoutube
} from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";

import { motion } from 'framer-motion';
import "../styles/thankyou.css"

const ThankYouPage = () => {

    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true);
        }, 200); // Small delay for animation
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            document.querySelectorAll(".confetti-piece").forEach(piece => {
                piece.classList.add("animate-confetti");
            });
        }, 500);
        return () => clearTimeout(timer);
    }, []);



    return (
        <div className="bg-gradient-to-r from-blue-50 via-blue-100 to-blue-50 text-gray-800">
            {/* Thank You Section */}
            <div className="bg-slate-300 text-white py-16 px-8 text-center">
                <section className="relative flex mt-20 flex-col items-center py-10 px-4 bg-gradient-to-r from-green-100 to-blue-50 text-center w-full max-w-lg mx-auto rounded-lg shadow-lg">
                    {/* Animated Checkmark Icon */}
                    <div className="flex justify-center mb-6">
                        <div className="checkmark bg-green-500 w-16 h-16 rounded-full flex items-center justify-center text-white text-4xl">
                            ✔️
                        </div>
                    </div>

                    {/* Thank You Message */}
                    <h2 className="text-3xl font-bold text-gray-800 mb-2 fade-in-animation">
                        Thank You for Choosing ConciseNext Solutions!
                    </h2>
                    <p className="text-md text-gray-700 mb-6 fade-in-delayed">
                        We appreciate your interest in our QuickBooks Hosting services. Your request has been successfully submitted, and our team is excited to assist you.
                    </p>

                    {/* Confetti Effect */}
                    <div className="confetti-container absolute inset-0 overflow-hidden pointer-events-none">
                        {[...Array(15)].map((_, i) => (
                            <div key={i} className="confetti-piece" style={{ "--i": i }}></div>
                        ))}
                    </div>
                </section>
            </div>

            {/* Our Mission Section */}
            <section className="py-16 px-8 bg-white text-center">
                <h2 className="text-3xl font-bold text-blue-600 mb-6">Our Mission</h2>
                <p className="text-gray-700 max-w-3xl mx-auto mb-10">
                    At ConciseNext Solutions, our mission is to empower businesses by providing innovative and reliable QuickBooks Hosting services that enhance productivity, efficiency, and financial management. We strive to be a trusted partner for our clients, delivering tailored solutions that meet their unique needs while ensuring the security and integrity of their data.
                </p>

                {/* Key Elements */}
                <div className="grid gap-8 md:grid-cols-4 max-w-7xl mx-auto">
                    {[
                        { icon: <FaHandshake className="text-5xl text-blue-500" />, title: 'Customer Success', description: 'We are committed to the success of our clients. By understanding their challenges and goals, we provide personalized support and solutions that help them thrive in a competitive landscape.' },
                        { icon: <FaLightbulb className="text-5xl text-yellow-500" />, title: 'Innovation', description: 'We continuously seek innovative ways to enhance our services, utilizing the latest technologies to streamline processes and improve user experiences. Our commitment to innovation ensures that our clients benefit from the best solutions available.' },
                        { icon: <FaLock className="text-5xl text-red-500" />, title: 'Security & Reliability', description: 'Protecting our clients data is our top priority. We implement robust security measures and reliable hosting solutions to ensure that our clients can access their QuickBooks software with confidence, knowing their data is secure.' },
                        { icon: <FaHeadset className="text-5xl text-green-500" />, title: 'Exceptional Support', description: 'Our dedicated team is available 24/7 to assist clients with any questions or issues they may encounter. We believe that exceptional customer support is key to building long-lasting relationships and fostering client satisfaction.' },

                    ].map((item, index) => (
                        <motion.div
                            key={index}
                            className="bg-gray-50 p-6 rounded-lg shadow-lg text-center"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, delay: index * 0.2 }}
                        >
                            {item.icon}
                            <h3 className="text-xl font-semibold text-blue-600 mt-4">{item.title}</h3>
                            <p className="text-gray-600 mt-2">{item.description}</p>
                        </motion.div>
                    ))}
                </div>
            </section>

            {/* What Happens Next Section */}
            <section className="py-16 px-6 bg-gradient-to-r from-blue-50 via-blue-100 to-blue-50 text-center">
                <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-lg p-8 transform hover:scale-105 transition duration-300 ease-in-out">
                    <h2 className="text-3xl font-extrabold text-blue-600 mb-4">What Happens Next?</h2>

                    <div className="grid gap-4">
                        <div className="flex items-start gap-4 text-left">
                            <FaCheckCircle className="text-blue-500 mt-1 text-xl" />
                            <p className="text-gray-700 text-md">
                                Our team will review your inquiry and get back to you shortly with the information you need.
                            </p>
                        </div>
                        <div className="flex items-start gap-4 text-left">
                            <FaCheckCircle className="text-blue-500 mt-1 text-xl" />
                            <p className="text-gray-700 text-md">
                                Whether you have questions about our services or need help choosing the right solution for your business, we’re here to help.
                            </p>
                        </div>
                        <div className="flex items-start gap-4 text-left">
                            <FaCheckCircle className="text-blue-500 mt-1 text-xl" />
                            <p className="text-gray-700 text-md">
                                If you have any immediate questions, feel free to reach out to us at any time.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Contact Us Section */}
            <section className="py-16 px-8 bg-white text-center">
                <h2 className="text-3xl font-bold text-blue-600 mb-6">Contact Us</h2>
                <p className="text-gray-700 max-w-2xl mx-auto mb-10">Feel free to reach out to us with any questions or immediate needs.</p>
                <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-10">
                    {[
                        { icon: <FaEnvelope className="text-3xl text-blue-500" />, title: 'Email', content: 'support@concisenext.com' },
                        { icon: <FaPhone className="text-3xl text-blue-500" />, title: 'Phone', content: '+1 (817) 803-6797' },
                        { icon: <FaMapMarkerAlt className="text-3xl text-blue-500" />, title: 'Address', content: 'CONCISENEXT INFOTECH LLC 5900, BALCONES DRIVE, STE. 100; AUSTIN, TEXAS-78731' },
                    ].map((item, index) => (
                        <div key={index} className="bg-gray-50 p-6 rounded-lg shadow-lg flex items-center space-x-4">
                            {item.icon}
                            <div>
                                <h3 className="text-xl font-semibold text-blue-600">{item.title}</h3>
                                <p className="text-gray-600">{item.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Explore More Section */}
            <section class="explore-more py-16 px-8 bg-gradient-to-r from-blue-50 to-white text-center">
                <h2 class="text-4xl font-bold text-gray-800 mb-6">Explore More</h2>
                <p class="text-lg text-gray-600 mb-10">While you wait for our response, feel free to explore our website for more information about our services</p>

                <div class="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-7xl mx-auto">
                    <div class="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition duration-300 ease-in-out">
                        <h3 class="text-xl font-semibold text-blue-600 mb-2">About Us</h3>
                        <p class="text-gray-700">Learn more about our mission and values.</p>
                        <a href="/about-us" class="text-blue-500 hover:underline mt-4 block">Read More</a>
                    </div>
                    <div class="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition duration-300 ease-in-out">
                        <h3 class="text-xl font-semibold text-blue-600 mb-2">Services</h3>
                        <p class="text-gray-700">Discover our comprehensive range of QuickBooks Hosting solutions.</p>
                        <a href="/services" class="text-blue-500 hover:underline mt-4 block">Explore Services</a>
                    </div>
                    <div class="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition duration-300 ease-in-out">
                        <h3 class="text-xl font-semibold text-blue-600 mb-2">FAQs</h3>
                        <p class="text-gray-700">Find answers to common questions about our services.</p>
                        <a href="/faqs" class="text-blue-500 hover:underline mt-4 block">View FAQs</a>
                    </div>
                </div>
            </section>


            <section className="py-8 px-4 text-center bg-gray-50 mt-12">
                <div className="max-w-md mx-auto bg-white rounded-md shadow-md p-6 transform hover:scale-105 transition duration-300 ease-in-out">
                    <p className="text-lg font-semibold text-gray-700 mb-2">Best Regards,</p>
                    <p className="text-2xl font-bold text-blue-600">The ConciseNext Solutions Team</p>
                    <div className="border-t-2 border-blue-200 w-20 mx-auto mt-4"></div>
                </div>
            </section>

            {/* Footer / Social Media */}
            <footer className="bg-blue-600 text-white py-6 text-center">
                <p className="mb-4">Follow us on social media for the latest updates:</p>
                <div className="flex justify-center space-x-6 text-2xl">
                    <a href="https://www.facebook.com/concisenext/" target="_blank" rel="noopener noreferrer">
                        <FaFacebook />
                    </a>
                    <a href="https://x.com/concisenext" target="_blank" rel="noopener noreferrer">
                        <FaXTwitter />
                    </a>
                    <a href="https://www.linkedin.com/company/concisenext/" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin />
                    </a>
                    <a href="https://www.instagram.com/concisenext/" target="_blank" rel="noopener noreferrer">
                        <FaInstagram />
                    </a>
                    <a href="https://www.youtube.com/@concisenext" target="_blank" rel="noopener noreferrer">
                        <FaYoutube />
                    </a>
                </div>
                <p className="mt-6">Thank you once again for choosing ConciseNext Solutions. We look forward to working with you!</p>
            </footer>
        </div>
    );
};

export default ThankYouPage;
